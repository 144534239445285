import { getUserAuth } from '../service/newGetData'
import UserShopAuth from '../object/UserShopAuth'



const showAuthMixins = {
  data() {
    return {
      userShopAuth: {}
    }
  },
  methods: {
    getUserShopAuthHandle() {
      try {
        return getUserAuth().then((res) => {
          if( res.e === 0 ) {
            this.userShopAuth = new UserShopAuth(res.d)
            return this.userShopAuth
          }
          return {}
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
}

export default showAuthMixins
