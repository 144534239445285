/** axios
 * @author  wiki
 * @date    2019-3-4 13:26:17
 * @last    2019-3-4 13:26:17
 *
 * @param  {Object}  data
 */
import axios from 'axios'
import qs from 'qs'
import signature from './signatureMobile'
import errorCodes from './errorCode'
import store from '../store/store'
import mutations from '../store/mutations'
import Location from '../object/Location'
import {nowDomain} from '../config/env'
// import { FCSignature } from './encrypt'
// import { getAliFCGMT } from './date'

// 错误请求重试次数
const REQUEST_COUNT = 3

// 当前请求重试次数
const CURRENT_COUNT = {}

// 请求重试延时
const REQUEST_DELAY = [3000, 5000, 10000]
// 不提示的错误码
const NOT_PROMPT_ERROR_CODE = [0, 1206, 1350, 1701, 2705, 3605, 20009, 10002, 20010, 100003, 100004, 300005, 300008, 17007, 17009, 10006, 10014]
// 不报错的接口
const NOT_THROW_ERROR_API = ['/rest/v3/fup', '/rest/v3/roshan', '/rest/v4c/us', '/v4/timing']
// NOT_THROW_ERROR_API不报错接口中的例外情况，需要报错的错误码
const NOT_THROW_ERROR_API_IGNORE = [300011]
// 统计接口状态
// const STATISTICS_API_STATE = ['/rest/v3/fpl', '/rest/v3/fup', '/rest/v3/fa']

export default async function send (url, data, method = 'get', sign) {
  // 阻止在https环境下发送http请求
  if (location.protocol.split(':')[0] === 'https' && new Location(url).protocol.split(':')[0] === 'http') {
    console.warn('阻止在https环境下发送http请求，请求路径：', url)
    return
  }

  // 把请求方式转成小写
  method = method.toLowerCase()

  // 当签名参数没有时取数据参数
  sign = sign || data

  // 构造请求路径
  let requestUrl = ''
  if (url.indexOf('/rest') > 0 || url.indexOf('biu.') > 0 || url.indexOf('ai.') > 0) {
    requestUrl = signature(url, method === 'post' ? {} : data, sign)
  } else {
    requestUrl = url
  }

  // 请求状态
  const state = {
    url,
    data,
    method,
    timestamps: new Date().getTime()
  }

  // 请求体
  try {
    let response = await request[method](requestUrl, method === 'post' ? data : null)
    if (typeof response.data === 'string' && /^https:\/\/aa\.\w+\.com\/oss_url_/.test(response.data)) {
      response = await send(response.data)
    }
    response.request = state
    state.errorCode = response.errorCode
    if (state.errorCode > 0) {
      window.$track.add({
        topic: '接口请求异常',
        _level_: 'ERROR',
        code: state.errorCode, //错误码
        requestURL: state.url, //  请求接口
        requestParams: state.data, // 请求参数
        requestData: JSON.stringify(response), // 返回数据
        httpCode: response.httpCode, // http的状态码
    })
    }
    // sendStatistics(state)
    return Promise.resolve(response)
  } catch (error) {
    state.errorCode = -1
    // sendStatistics(state)

    CURRENT_COUNT[state.url] = CURRENT_COUNT[state.url] || 0
    if (CURRENT_COUNT[state.url] < REQUEST_COUNT) {
      setTimeout(() => {
        send(url, data, method, sign)
      }, REQUEST_DELAY[CURRENT_COUNT[[state.url]]])
      CURRENT_COUNT[state.url]++
    } else {
      CURRENT_COUNT[state.url] = 0
      Promise.reject(error)
    }
  }
}

// 新建 axios 请求对象
const request = axios.create({
  timeout: 50000,
  headers: {
    Accept: 'application/json'
    // 'Content-Type': 'application/x-www-form-urlencoded'
  }
})

// 添加请求拦截器，POST传参序列化
request.interceptors.request.use(function (config) {
  if (config.method === 'post' || config.method === 'put' || config.method === 'delete') {
    // 序列化
    config.data = qs.stringify(config.data)
  }
  // TODO: 函数计算签名方法（暂时无法前端签名）
  // if (config.url.indexOf('//cal.') >= 0) {
  //   config.headers['x-fc-Date'] = getAliFCGMT()
  //   config.headers['Authorization'] = FCSignature(config.url, config.method.toLocaleUpperCase(), config.headers)
  // }
  return config
}, function (error) {
  window.$notify('请求错误，请联系客服<br/>电话：0571-87711285')
  console.warn(error)
  return Promise.reject(error.data.msg)
})

// 添加响应拦截器，进行错误处理
request.interceptors.response.use(function (response) {
  const res = transformV4(response.data)
  res.httpCode = response?.status || null

  // 返回结果异常时，抛出错误
  if (window.isNull(res.e)) {
    return Promise.reject(res.e)
  }
  // 判断是否要抛出错误
  let isThrowError = true
  for (let i = 0, len = NOT_THROW_ERROR_API.length; i < len; i++) {
    if (response.config.url.includes(NOT_THROW_ERROR_API[i]) && !NOT_THROW_ERROR_API_IGNORE.includes(res.e)) {
      isThrowError = false
      break
    }
  }

  if (isThrowError && NOT_PROMPT_ERROR_CODE.includes(res.e)) {
    isThrowError = false
  }

  /**
   * 用户未登录，但是不需要登陆
   * 场景：前端登录着，但是后台判断登录过期时，触发的登录不是用户主动操作
   */
  if (res.e === 100004) {
    mutations.DELETE_USERINFO(store.state)
    isThrowError = false
  }
  if (res.e === 1222 && location.pathname === '/') {
    location.href = `https://www.${nowDomain}.com/domain404`
    isThrowError = false
  }

  if (isThrowError) {
    // 处理错误
    if ([2, 5, 1008, 10005, 20006, 100006, 200001, 300017].indexOf(res.e) >= 0) {
      [2, 100006, 300017].indexOf(res.e) >= 0 && window.$notify('请登录后操作');
      [5, 1008, 10005, 20006, 200001].indexOf(res.e) >= 0 && window.$notify('登录信息已过期，请重新登录')
      mutations.DELETE_USERINFO(store.state)
      setTimeout(() => {
        window.$loginFromErrorCode(res.e)
      }, 500)
    } else {
      window.$notify((errorCodes[res.e] || res.m || '未知错误'))
    }
  }
  return Promise.resolve(res)
}, function (error, a) {
  let isThrowError = false
  for (let i = 0, len = NOT_THROW_ERROR_API.length; i < len; i++) {
    if (error.config.url.indexOf(NOT_THROW_ERROR_API[i]) >= 0) {
      isThrowError = true
      break
    }
  }
  if (!isThrowError && CURRENT_COUNT[error.config.url] >= REQUEST_COUNT) {
    if (error.message === 'Network Error') {
      window.$notify('网络错误，请检查您的手机网络是否正常')
      return
    }
    window.$notify('响应错误，请联系客服<br/>电话：0571-87711285')
  }
  return Promise.reject(error)
})

// function sendStatistics (state) {
//   // TODO: 接口统计代码
//   for (let i = 0, len = STATISTICS_API_STATE.length; i < len; i++) {
//     if (state.url === STATISTICS_API_STATE[i]) {
//       statistics(9, {
//         actId: state.data.a,
//         sepId: state.data.s || 0,
//         name: state.url,
//         time: state.timestamps,
//         errorCode: state.errorCode,
//         total: new Date().getTime() - state.timestamps
//       })
//     }
//   }
// }

// 返回数据转成v4版本
function transformV4 (res) {
  let after
  if (Object.keys(res).indexOf('errorCode') >= 0) {
    after = {
      e: res.errorCode,
      d: res.data,
      m: res.msg
    }
  } else {
    after = res
  }
  return after
}
