const parseString =  (str) => {
  // 匹配 & 符号中间的内容
  const andPattern = /&(.*?)&/g
  // 匹配 ¥ 符号中间的内容
  const yuanPattern = /¥(.*?)¥/g

  // 替换 & 符号中间的内容为红色
  let result = str.replace(andPattern, (match, p1) => {
    return `<span style="color: #FF8D29;">${p1}</span>`
  })

  // 替换 ¥ 符号中间的内容为红色加粗
  result = result.replace(yuanPattern, (match, p1) => {
    return `<span style="font-size: 12px">${p1}</span>`
  })
  return result
}
const btnBackground = {
  0: 'linear-gradient(180deg, #6FA8FF 0%, #0055FF 100%)',
  1: 'linear-gradient(180deg, #FFBC61 0%, #FF5304 100%)',
  2: '#F0FCEA'
}

const btnTypeMap = {
  0: {
    btnTextMap: {
      0: '去创建',
      2: '已完成'
    },
    btnTextColor: {
      0: '#fff',
      2: '#52C41A'
    },
    btnBackground: {...btnBackground, 0: '#FF455E'},
  },
  1: {
    btnTextMap: {
      0: '去上传',
      1: '领取奖励',
      2: '已完成'
    },
    btnTextColor: {
      0: '#fff',
      1: '#fff',
      2: '#52C41A'
    },
    btnBackground,
  },
  2: {
    btnTextMap: {
      0: '去分享',
      1: '领取奖励',
      2: '已完成'
    },
    btnTextColor: {
      0: '#fff',
      1: '#fff',
      2: '#52C41A'
    },
    btnBackground
  }
}
export default class Task {
  constructor (data = {}) {
    this.missionType = data.missionType // 任务类型 0:创建直播相册 1:往相册中上传照片 2:分享相册至微信预览 
    this.missionTitle = data.missionTitle
    this.missionRewardsUrl = data.missionRewardsUrl // 描述中的小图标
    this.missionRewards = parseString(data.missionRewards) // 奖励的描述（兼容老app）
    this.missionState = data.missionState // 任务状态 0:未完成 1:待领取 2:已完成
    this.missionUrl = data.missionUrl
    this.text = btnTypeMap[data.missionType].btnTextMap[data.missionState]
    this.btnBgc = btnTypeMap[data.missionType].btnBackground[data.missionState]
    this.btnTextColor = btnTypeMap[data.missionType].btnTextColor[data.missionState]
    
  }
  changeState (state) {
    this.missionState = state
    this.text = btnTypeMap[this.missionType].btnTextMap[this.missionState]
    this.btnBgc = btnTypeMap[this.missionType].btnBackground[this.missionState]
    this.btnTextColor = btnTypeMap[this.missionType].btnTextColor[this.missionState]
  }
}
