import axios from '../module/axios'
import { fssUrl, albumRevisionUrl } from '../config/env'

export const getIndexNewsAxios = () => axios(fssUrl + `/rest/v3/query/index/queryNews`, {}, 'post')

// pc 主页获取图片分类 及 合作伙伴数据
export const getImgDataAxios = () => axios(fssUrl + `/rest/v3/query/album/queryPcHomeData`, {}, 'get');

//pc获取对应域名的页面信息
export const getUserPageInfoAxios = (sub) => axios(fssUrl + `/rest/v3/user/query/allPcProDetail`, {sub}, 'post');

// 获取新pc首页的banner案例
export const getNewIndexBannerCasesAxios = () => axios(fssUrl + `/rest/v3/query/index/queryIndexBannerList`, {}, 'post');

// pc主页获取视频案例
export const getIndexVideoCasesAxios = (cnt) => axios(fssUrl + `/rest/v3/query/index/queryVideoCase`, {cnt}, 'post')


// pc 短信验证接口 使用ip限流
export const postSmsVerAxios = ({ phone, vcodeType = 13, globalCode = "" }) => axios(fssUrl + '/rest/v3/user/base/sendValidCodeByPhone', { phone, vcodeType, globalCode }, 'post');


// pc 首页提交合作申请
export const postCooperationAxios = ({ contact="", phone="", actId = "", content="", code = "", address="", backFrom }) => axios(fssUrl + '/rest/v3/user/base/submitHomeCoopOrder', { contact, phone, actId, content, code, address, backFrom }, 'post');

