<template>
  <div class="rightSideNav" v-if="isShowRightNav">
    <aside class="rightSideNav-backTop">
      <div>
        <div class="rightSideNav-backTop-li" v-if="showShare" style="color:#2998ff;">
          <div @click="openActivity">
            <div class="rightSideNav-backTop-li-icon">
              <i class="iconfont icon-activity"></i>
            </div>
            <span class="rightSideNav-backTop-li-text">活动</span>
          </div>
          <section :class="[nowShowActivityClass]">
              <img @click="openImgAcitivy" :src="nowShowActivity" >
          </section>
          <img @click="openIntroAcitivy" class="rightSideNav-backTop-li-albuml-intro" :src="`${imgBaseUrl}/albuml/introAlbumButton.png`">
        </div>
        <!-- 跳动的图像 -->
        <!-- <section class="rightSideNav-backTop-li-albuml" v-show="isTenYearGiftPeriod" @click="openNewWorkbench">
          <div class="close" @click.stop="isTenYearGiftPeriod = false">
            <i class="iconfont icon-close"></i>
          </div>
          <img src="@/images/2023newYear/newYearGift.png" />
          <div v-if="showBubble" class="bubble" :class="bubbleClassName">
            <img class="gift" src="@/images/2023newYear/gift.png" alt="">
            {{tipText}}
            <img class="firework" src="@/images/2023newYear/firework.png" alt=""/>
          </div>
        </section> -->
        <div class="rightSideNav-backTop-li" @click="backTop" v-show="isShowGoTop">
          <div class="rightSideNav-backTop-li-icon">
            <i class="iconfont icon-huidingbu"></i>
          </div>
          <span class="rightSideNav-backTop-li-text">回顶部</span>
        </div>
        <div class="line"></div>
        <div class="rightSideNav-backTop-li" style="position: relative;">
          <a href='http://faq.alltuu.com/01/c9df' target='_blank'>
            <div class="rightSideNav-backTop-li-icon">
              <i class="iconfont icon-picLive"></i>
            </div>
            <span class="rightSideNav-backTop-li-text">支持机型</span>
            <div class="rightSideNav-backTop-li-desc">
              支持佳能、尼康、索尼、富士、松下等品牌的<span style="font-weight: 600">上百款专业相机型号</span>，满足专业图片直播需求~<br />支持🔥苹果有线闪传、👍🏻安卓有线闪传及无线FTP闪传
            </div>
          </a>
        </div>
        <!-- <div class="rightSideNav-backTop-li" style="position: relative;">
          <a>
            <div class="rightSideNav-backTop-li-icon remen">
              <i class="iconfont icon-remen"></i>
            </div>
            <span class="rightSideNav-backTop-li-text">热门动态</span>
            <div class="rightSideNav-backTop-li-gongzhong">
              <img v-if="hotPhotoWechat" src="../images/qr/alltuuPhotographer.png" />
              <img v-else src="../images/qr/gongzhong.png" />
              <p>扫码关注<br>{{ hotPhotoWechat ? '【喔图闪传】' : '【喔图云摄影】' }}</p>
            </div>
          </a>
        </div> -->
        <div class="line"></div>
        <div class="rightSideNav-backTop-li" style="position: relative;">
          <a>
            <div class="rightSideNav-backTop-li-icon">
              <i class="iconfont icon-kefu"></i>
            </div>
            <span class="rightSideNav-backTop-li-text">客服</span>
            <div class="rightSideNav-backTop-li-weixin">
              <img src="@/images/qr/alltuuPhotographer.png" />
              <p>关注公众号<br>咨询客服</p>
            </div>
          </a>
        </div>
        <div class="line"></div>

        <router-link :to="{ name: 'about-feedback' }" @click.native="$statistics('侧边栏一反馈')">
          <div class="rightSideNav-backTop-li">
            <div class="rightSideNav-backTop-li-icon">
              <i class="iconfont icon-rebat"></i>
            </div>
            <span class="rightSideNav-backTop-li-text">反馈</span>
          </div>
        </router-link>
        <div class="line" v-if="isAlbuml"></div>
        <!-- <div class="rightSideNav-backTop-li" @click="openGuide" v-if="isAlbuml">
          <div class="rightSideNav-backTop-li-icon">
            <i class="iconfont icon-caozuobangzhu"></i>
          </div>
          <span class="rightSideNav-backTop-li-text">操作帮助</span>
        </div> -->
      </div>
    </aside>

    <!-- 下载APP -->
    <a v-if="isShowDownload" class="rightSideNav-download" :href="`https://www.${nowDomain}.com/cooperationPage#download`">
      <div class="rightSideNav-download-icon">
        <i class="iconfont icon-retouchdown"></i>
      </div>
      <span class="rightSideNav-download-text">下载APP</span>
    </a>
    <QRTip v-if="showQRtip" @close="showQRtip=false">
      <img src="./assets/appQR.png" alt="" style="width: 366px">
    </QRTip>

    <!-- 在线咨询 -->
    <!-- <div class="rightSideNav-zixun">
      <a class="rightSideNav-zixun-block" href='http://p.qiao.baidu.com/cps/chat?siteId=11712735&userId=25041363&siteToken=b3b22cc6678277c3f40a10fdcc32e5a5' target="_blank" rel="external nofollow">
        <div class="rightSideNav-backTop-li-icon">
          <i class="iconfont icon-zaixianzixun"></i>
        </div>
        <span class="rightSideNav-backTop-li-text">在线咨询</span>
      </a>
    </div> -->
    <!-- 弹出的广告  喔图5周年 -->
    <!-- <advermasker class="rightSideNav-masker" v-if="isShowAdv && userInfo && userShopAuth.canSeeAlbumMemberAdv" @click="closeAdv" :close="showAni">
      <div class="rightSideNav-masker-adver" @click.stop="toAdv" :close="showAni">
        <span class="rightSideNav-masker-adver-icon" @click.stop="closeAdv">
          <i class="iconfont icon-close"></i>
        </span>
        <img src="./assets/rightNav/camera720Alart.png" alt />
      </div>
    </advermasker> -->
    <!-- 3.3折广告 -->
    <!-- <advermasker class="rightSideNav-othmasker" v-if="showOthAdv">
      <div class="rightSideNav-othmasker-advbox" @click="toBuy">
        <span class="rightSideNav-masker-adver-icon" @click.stop="closeOthAdv">
          <i class="iconfont icon-close"></i>
        </span>
      </div>
    </advermasker> -->

    <appleFlashVideoPlay v-if="showVideo" @close="showVideo = false" :item="videoItem"/>

  </div>
</template>
<script>
import { imgBaseUrl } from "../config/env.js";
import "../plugins/animation.js";
import createQR from "./createQR";
import advermasker from "./advermasker";
import shopAuth from '@/mixins/shopAuth'
import { User } from "../object/User";
import { nowDomain } from '@/config/env';
import QRTip from "@/components/QRTip.vue"
import appleFlashVideoPlay from '@/components/appleFlashVideoPlay.vue'
import { getNewYearGift } from "@/service/newGetData.js"

export default {
  props: {
    showShare: {
      type: Boolean,
      default: true
    },
    showImg: {
      type: Boolean,
      default: true
    }
  },
  mixins: [shopAuth],
  data() {
    return {
      isShowAdv: false,
      showOthAdv: false, // 是否显示3.3折
      showAni: false,
      requested: false, // 是否请求过3.3折接口
      isShowedOth: null,
      showedAdv: false, // 是否自动展示过
      userId: this.$store.state.userInfo.userId,
      routerPaths: [
        "index",
        "cases",
        "videoCases",
        "appDownload",
        "cooperationPage",
        "openPlatform",
        "aiIntroduce",
        "aiSpin",
        "specialTopic",
        "retouchDownload",
        "aboutus",
        "personalCenter",
        "albuml",
        "work",
        "detail"
      ], // 允许展示的页面
      isAlbuml: false,
      isShowGoTop: false,
      showQRtip: false,
      videoItem: {
        videoUrl: 'https://spu.alltuu.com/apk/download/img/ios_vedio.mp4'
      },
      showVideo: false,
      nowDomain,
      isTenYearGiftPeriod: false, // 是否在喔图十重礼期间
      tenYearType: 0, // 当前在几重礼期间
      bubbleClassName: '',
      showBubble: false,
      imgBaseUrl

    };
  },
  // created () {
  //   this.isShowedOth = localStorage.getItem('showedOth')
  //   if (this.isShowedOth && this.isShowedOth == this.$store.state.userInfo.userId) { // 已经给此用户展示过了
  //     this.canShowAdv()
  //   } else if (this.$store.state.userInfo.userId) {
  //     this.isJoinActive()
  //   } else {
  //     this.canShowAdv()
  //   }
  // },
  components: {
    createQR,
    advermasker,
    QRTip,
    appleFlashVideoPlay
  },
  mounted() {
    if( this.userInfo.nike ) {
      this.getUserShopAuthHandle()
    }
    window.addEventListener("scroll", this.onScroll);
    // this.getNewYearInfo()
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    // async getNewYearInfo () {
    //   const res = await getNewYearGift()
    //   if (res.e === 0) {
    //     this.newYearType = res.d.type
    //     this.isTenYearGiftPeriod = ![0, 5].includes(res.d.type)
    //     if ([0, 5].includes(res.d.type)) return
    //     const localType = localStorage.getItem('SPRING_ACTIVE_TYPE') || ''
    //     if ((localType && Number(localType) !== res.d.type) || !localType) {
    //       this.showBubble = true
    //       localStorage.setItem('SPRING_ACTIVE_TYPE', res.d.type)
    //       setTimeout(() => {
    //         this.bubbleClassName = 'closeBubble'
    //       }, 3000)
    //     }
    //   }
    // },
    isJoinActive() {
      this.isShowedOth = JSON.parse(localStorage.getItem("showedOth"));
      if (!Array.isArray(this.isShowedOth))
        this.isShowedOth = Array.of(this.isShowedOth);
    },
    setShowedOth() {
      let arr = localStorage.getItem("showedOth") || [];
      if (!Array.isArray(arr)) arr = Array.of(arr);
      if (!arr.includes(this.userId)) arr.push(this.userId);
      localStorage.setItem("showedOth", JSON.stringify(arr));
    },
    toBuy() {
      // localStorage.setItem('showedOth', this.userId)
      this.setShowedOth();
      this.$router.push({ name: "buyService" });
      this.showOthAdv = false;
    },
    closeOthAdv() {
      this.showOthAdv = false;
      // localStorage.setItem('showedOth', this.userId)
      this.setShowedOth();
      // this.canShowAdv()
    },
    canShowAdv() {
      this.showedAdv = JSON.parse(localStorage.getItem("showedAdv"));
      if (!this.showedAdv && this.$store.state.userInfo.pgType === 0) {
        this.isShowAdv = true;
      }
    },
    toAdv() {
      localStorage.setItem("showedAdv", true);
      this.isShowAdv = false;
      this.$router.push({ name: "buy720Camera" });
      // window.open('https://h.alltuu.com/alltuu5thAnniversary/index.html', '_blank')
    },
    openImgAcitivy() {
      // 点击图片事件
      this.isShowAdv = true;
      this.$statistics("侧边栏一广告");
      // window.open('https://weidian.com/item.html?itemID=3063080813', '_blank')
    },
    openNewWorkbench() {
      // this.showVideo = true
      this.$router.push({name: 'albumList', query: {openNewYearAlert: true}})
    },
    closeAdv() {
      // 关闭广告
      this.showAni = true;
      if (!this.showedAdv) localStorage.setItem("showedAdv", true);
      setTimeout(() => {
        this.isShowAdv = false;
        this.showAni = false;
      }, 800);
    },
    openIntroAcitivy() {
      window.open("/yearIntroduction");
    },
    openActivity() {
      this.$emit("openActivity");
    },
    //回滚到顶部
    backTop() {
      if (this.$route.hash) {
        this.$router.replace({hash: undefined})
      }
      this.$statistics("侧边栏一回顶部");
      Math.animation(this.getScrollTop(), 0, 500, "Quad.easeOut", value => {
        window.scrollTo(0, value);
      });
    },
    //获取滚动条当前的位置
    getScrollTop() {
      let scrollTop = 0;
      if (document.documentElement && document.documentElement.scrollTop) {
        scrollTop = document.documentElement.scrollTop;
      } else if (document.body) {
        scrollTop = document.body.scrollTop;
      }
      return scrollTop;
    },
    // 打开引导
    openGuide(){
      Math.animation(document.documentElement.scrollTop || document.body.scrollTop,  620 , 'Cubic.easeOut', function(value) {
        window.scrollTo( 0, value)
      });
      this.$emit('openGuide')
    },
    onScroll(){
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      if(scrollTop > 636){
        this.isShowGoTop = true
      }else{
        this.isShowGoTop = false
      }
    }
  },
  watch: {
    $route(val) {
      if (this.requested && val.name != "buyService" && !this.showOthAdv)
        this.canShowAdv();
      this.isJoinActive();
      this.isAlbuml = val.path === '/albuml' ? true : false
    }
  },
  computed: {
    tipText () {
      return this.newYearType === 3 ? '查看年度影像报告，参与新春抽奖，100%中奖！' : '三重好礼迎新春，快来领取你的专属礼物吧'
    },
    isShowDownload () {
      return this.$route.name === 'vToHomePage' || (nowDomain === 'alltuu' && this.$route.name === 'homePageIndex')
    },
    isShowRightNav () {
      return this.routerPaths.some(item => this.$route.path.substring(1).indexOf(item) > -1 && this.$route.path.substring(1).indexOf('workbench') === -1 && this.$route.path.indexOf('newCases') === -1) || ( this.$route.name === 'homePageIndex' && nowDomain === 'alltuu')
    },
    userInfo () {
      return User(this.$store.getters.getUserInfo)
    },
    hotPhotoWechat() {
      return this.$route.fullPath.includes('personalCenter') || this.$route.name === 'albuml'
    }
  }
};
</script>
<style lang="less">
.rightSideNav {
  &-masker {
    // 弹出来的广告
    &[close] {
      animation: faded 0.8s;
    }
    &-adver {
      cursor: pointer;
      user-select: none;
      position: absolute;
      bottom: 50%;
      right: 50%;
      transform: translate(50%, 60%);
      height: 80vh;
      &[close] {
        animation: packup 0.8s;
      }

      &-btn {
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
      }
      img {
        width: 800px;
        height: 472px;
      }
      // 关闭按钮
      &-icon {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
        font-size: 20px;
        color: #f7f7f7;
        &:hover {
          color: #fff;
        }
      }
    }
  }
  &-othmasker {
    &-advbox {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 288px;
      height: 350px;
      background: url("../../static/img/components/price3.3.png") no-repeat;
      background-size: contain;
      cursor: pointer;
      &-icon {
      }
    }
  }
  @keyframes faded {
    to {
      opacity: 0;
    }
  }
  @keyframes packup {
    to {
      bottom: 320px;
      right: 90px;
      transform: translate(50%, 50%) scale(0);
    }
  }
  &-zixun{
    display: block;
    position: fixed;
    z-index: 50;
    right: 50px;
    bottom: 100px;
    font-size: 16px;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.13);
    text-align: center;
    cursor: pointer;
    &-block{
      display: block;
      width: 60px;
      height: 59px;
    }
    &:hover{
      i{
        color: #2970ff;
      }
      span{
        color: #2970ff;
      }
      a{
        background: rgba(41, 112, 255, 0.25);
      }
    }
  }
  &-backTop {
    position: fixed;
    z-index: 50;
    right: 50px;
    bottom: 170px;
    font-size: 16px;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
    width: 60px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.13);
    .line{
      width: 49px;
      height: 1px;
      background: #EEEEEE;
      margin: 0 auto;
    }
    &-li {
      cursor: pointer;
      text-align: center;
      width: 60px;
      height: 59px;
      // margin-bottom: 10px;
      color: #cccccc;
      &-tally {
        position: absolute;
        width: 165px;
        right: -35px;
        top: -100px;
        animation: moveStart 2s infinite;
        z-index: 40;
        pointer-events: auto;
        & > img {
          width: inherit;
          pointer-events: auto;
        }
      }
      &-vToHomePage {
        position: absolute;
        width: 90px;
        right: -16px;
        top: -100px;
        animation: moveStart 2s infinite;
        z-index: 40;
        pointer-events: auto;
        & > img {
          width: inherit;
          pointer-events: auto;
        }
      }
      &-albuml {
        position: absolute;
        width: 115px;
        right: -27px;
        top: -140px;
        // animation: albumlMoveStart2 3s infinite;
        z-index: 40;
        pointer-events: auto;
        user-select: none;
        cursor: pointer;
        & > img {
          width: inherit;
          pointer-events: auto;
        }
        &-intro {
          position: absolute;
          width: 100px;
          right: -22px;
          z-index: 40;
          // top: -32px;
          pointer-events: auto;
        }
        .bubble {
          position: absolute;
          top: -60px;
          right: -6px;
          padding: 12px 0 12px 11px;
          background-color: #CD1713;
          border-radius: 8px;
          color: #FFF3DD;
          font-size: 15px;
          line-height: 21px;
          font-weight: bold;
          white-space: nowrap;
          max-width: 362px;
          transition: all 3s ease-out;
          .gift {
            width: 18px;
            height: 18px;
          }
          .firework {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 24px;
          }
          &::before {
            content: '';
            position: absolute;
            right: 40px;
            bottom: -19px;
            border-top: 12px solid #CD1713;
            border-right: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-left: 12px solid transparent;
          }
        }
        .closeBubble {
          max-width: 0;
          overflow: hidden;
          padding-left: 0px;
          &::before {
            display: none;
          }
        }
        .close {
          width: 18px;
          height: 18px;
          border-radius: 50%;
          color: #D8D8D8;
          border: 1px solid #D8D8D8;
          font-size: 10px;
          cursor: pointer;
          position: absolute;
          right: -6px;
          top: -8px;
          line-height: 16px;
          text-align: center;
          &:hover {
            opacity: 0.8;
          }
        }
      }
      &-icon {
        width: 40px;
        height: 32px;
        margin: 0 auto;
        line-height: 40px;
        font-size: 20px;
        color: #666666;
        & > .icon-huidingbu{
          font-size: 18px;
        }
        & > .icon-remen{
          font-size: 24px;
        }
      }
      .remen{
        line-height: 41px;
      }
      &-text {
        color: #666666;
        font-size: 12px;
        margin: 4px 0 10px;
        transition: color 0.3s;
      }
      &-desc {
        position: absolute;
        top: -10px;
        left: -336px;
        width: 320px;
        border-radius: 4px;
        color: #000;
        font-size: 12px;
        padding: 12px;
        box-sizing: border-box;
        background-color: #FFF;
        text-align: left;
        display: none;
        box-shadow: 0px 6px 16px 0px rgba(0,0,0,0.08), 0px 3px 6px -4px rgba(0,0,0,0.12);
      }
      &-desc::before {
        position: absolute;
        content: "";
        right: -6px;
        top: 50%;
        transform: translate(0, -50%);
        border-width: 7px;
        border-right-width: 0;
        border-style: solid;
        border-color: transparent;
        border-left-color: #FFF;
      }
      &-qr {
        position: absolute;
        top: -48px;
        right: 50px;
        width: 136px;
        height: 136px;
        display: none;
      }
      &-weixin {
        position: absolute;
        top: 0;
        right: 65px;
        width: 105px;
        height: 134px;
        display: none;
        background: #FFFFFF;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        font-size: 12px;
        color: #333333;
        text-align: center;
        & > img {
          margin-top: 5px;
          width: 92px;
          height: 92px;
        }
        p{
          color: #1B3468;
          margin-top: 4px;
        }
      }
      &-gongzhong {
        position: absolute;
        top: 0;
        right: 65px;
        width: 100px;
        height: 143px;
        display: none;
        background: #FFFFFF;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        font-size: 12px;
        color: #333333;
        text-align: center;
        & > img {
          margin-top: 5px;
          width: 90px;
          height: 91px;
        }
        p {
          margin-top: 4px;
        }
      }
    }
    &-li {
      &:hover &-icon {
        color: #2970FF;
      }
      &:hover &-text {
        color: #2970FF;
      }
      &:hover &-qr {
        display: block;
      }
      &:hover &-weixin {
        display: block;
      }
      &:hover &-gongzhong {
        display: block;
      }
      &:hover &-desc {
        display: block;
      }
    }
    &-li:hover {
      background: rgba(41, 112, 255, 0.25);
    }
  }
  &-download {
    position: fixed;
    z-index: 50;
    right: 50px;
    bottom: 100px;
    font-size: 16px;
    color: #fff;
    background: #2970FF;
    border-radius: 4px;
    width: 60px;
    height: 59px;
    text-align: center;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.13);
    cursor: pointer;
   
    &-icon {
      display: block;
      width: 40px;
      height: 32px;
      margin: 0 auto;
      line-height: 40px;
      font-size: 18px;
      text-align: center;
    }
    &-text {
      font-size: 12px;
      margin: 4px 0 10px;
      -webkit-transition: color 0.3s;
      transition: color 0.3s;
    }
    &:hover {
      opacity: 0.8;
    }

  }
  @keyframes moveStart {
    0% {
      top: -100px;
    }

    50% {
      top: -89px;
    }

    100% {
      top: -100px;
    }
  }
  @keyframes albumlMoveStart2 {
    0% {
      transform: translateY(-90px);
    }

    50% {
      transform: translateY(-70px);
    }

    100% {
      transform: translateY(-90px);
    }
  }
}
</style>
